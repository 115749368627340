<template>
    <!-- 用于挂在router/index.js中的内容，挂载的意思是路由指向的视图（布局视图）会自动在这个区域加载 
         一级路由直接挂在在这里，二级路由挂在在一级路由的视图中。
    -->
    <div>
    <router-view />
    </div>
</template>

<style>

</style>
