const state = {
    getAllUrls: {
        showUrls:{}
    },
    getSelectedUrlName: {
        selectedUrl: ''
    },
    getColumns: {
        columns: [
            {
                title: '测试数据',
                width: 100,
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
            },
            {
                title: 'ID',
                width: 100,
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
            },
            {
                title: 'Column 1',
                dataIndex: 'address',
                key: '1',
                width: 150,
            },
            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 100,
                slots: {
                    customRender: 'action',
                },
            },
        ]
    },
    getData: {
        data: [{
            key: 'test',
            name: 'test',
            id: 'test',
            address: 'test',
        }]
    },
};

const actions = {
    submitData({ commit }, { payload }) {
        commit("saveStepFormData", { payload });
    }
};

const mutations = {
    saveGetAllUrlsData(state, { payload }) {
        state.getAllUrls = {
            ...payload
        };
    },
    savegetSelectedUrlNameData(state, { payload }) {
        state.getSelectedUrlName = {
            ...payload
        };
    },
    saveColumns(state, { payload }) {
        state.getColumns = {
            ...payload
        };
    },
    saveData(state, { payload }) {
        state.getData = {
            ...payload
        };
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};