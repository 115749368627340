// mainapp 中各种需传参api的数据

const state = {
  beforeExecData: {
    cmdUrls: {'请选择下列选项':''},
    SelectedCMD: '',
  },
  execAttr: {
    method: '',
    response: '',
    getType: '',
    param_order: undefined,
    allAttr: {}
  },
  execResult: {
    status: '',
    description: '',
    result: ''
  }
  };
  
  const actions = {
    submitData({ commit }, { payload }) {
      commit("saveStepFormData", { payload });
    }
  };
  
  const mutations = {
    saveData(state, { payload }) {
      state.beforeExecData = {
        ...payload
      };
    },
    saveExecResultData(state, { payload }) {
      state.execResult = {
        ...payload
      };
    },
    saveExecAttrData(state, { payload }) {
      state.execAttr = {
        ...payload
      };
    },
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
  };