const state = {
    server: {
        alternate: false
    }
};

const actions = {
    submitData({ commit }, { payload }) {
        commit("saveStepFormData", { payload });
    }
};

const mutations = {
    saveData(state, { payload }) {
        state.server = {
            ...payload
        };
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};