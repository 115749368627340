const state = {
    user: {
      username: '',
      authenticated: false,
      token: '',
    }
  };
  
  const actions = {
    submitData({ commit }, { payload }) {
      commit("saveStepFormData", { payload });
    }
  };
  
  const mutations = {
    saveData(state, { payload }) {
      state.user = {
        ...payload
      };
    }
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
  };