import { createStore } from "vuex";
// 本地持久化储存信息的插件。避免信息丢失。
import createVuexAlong from "vuex-along"
import user_login_auth from "@/store/auth/user_login_auth"
import import_excel_upload_addr from "./import_excel/import_excel_upload_addr";
import alternate_server from "./auth/alternate_server";
import data_alys_cmds from "./mainapp/data_alys_cmds";
import show_data from "./basic_data_manager/show_data";
import ingredients_status from "./mainapp/ingredients_status";


export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user_login_auth,
    import_excel_upload_addr,
    alternate_server,
    data_alys_cmds,
    show_data,
    ingredients_status
  },
  plugins: [
    createVuexAlong({
      // 设置保存的集合名字，避免同站点下的多项目数据冲突
      name: "formulator_store",
      local: {
        list: [],
        // 过滤模块 ma 数据， 将其他的存入 localStorage
        isFilter: true,
      },
      session: {
        // 保存模块 ma 中的 a1 到 sessionStorage
        list: [""],
      },
    }),
  ]
});
