const state = {
  uploadAddr: {
    addr: '',
    method: ''
    }
  };
  
  const actions = {
    submitData({ commit }, { payload }) {
      commit("saveStepFormData", { payload });
    }
  };
  
  const mutations = {
    saveData(state, { payload }) {
      state.uploadAddr = {
        ...payload
      };
    }
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
  };