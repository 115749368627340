const state = {
    selectedOrg: {
        org: ''
    },
    selectedDate: {
        date: undefined
    },
    selectedIngredient: {
        ingredient: ''
    },
    allIngredientsColumn: {
        columns: []
    },
    allIngredientsData: {
        data: []
    },
    selectedIngredientsData: {
        data: []
    }
};

const actions = {
    submitData({ commit }, { payload }) {
        commit("saveStepFormData", { payload });
    }
};

const mutations = {
    saveSelectedOrg(state, { payload }) {
        state.selectedOrg = {
            ...payload
        };
    },
    saveSelectedDate(state, { payload }) {
        state.selectedDate = {
            ...payload
        };
    },
    saveSelectedIngredient(state, { payload }) {
        state.selectedIngredient = {
            ...payload
        };
    },
    saveAllIngredientsColumn(state, { payload }) {
        state.allIngredientsColumn = {
            ...payload
        };
    },
    saveAllIngredientsData(state, { payload }) {
        state.allIngredientsData = {
            ...payload
        };
    },
    saveSelectedIngredientsData(state, { payload }) {
        state.selectedIngredientsData = {
            ...payload
        };
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};