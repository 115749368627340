import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
// 选择性导入组件
import {
    Button,
    Layout,
    Drawer,
    Radio,
    Menu,
    Form,
    Input,
    Select,
    LocaleProvider,
    Dropdown,
    DatePicker,
    Breadcrumb,
    Popover,
    Checkbox,
    Table,
    Space,
    Tooltip,
    Cascader,
    AutoComplete,
    Row,
    Col,
    InputNumber,
    Modal,
    Descriptions,
    Switch,
    Divider,
    Upload,
    Typography,
    Message,
  } from "ant-design-vue";

  // 注册组件
const app = createApp(App).use(store).use(router);
app.config.productionTip = false;
app.use(Button);
app.use(Layout);
app.use(Drawer);
app.use(Radio);
app.use(Menu);
app.use(Form);
app.use(Input);
app.use(Select);
app.use(LocaleProvider);
app.use(Dropdown);
app.use(DatePicker);
app.use(Breadcrumb);
app.use(Popover);
app.use(Checkbox);
app.use(Table);
app.use(Space);
app.use(Tooltip);
app.use(Cascader);
app.use(AutoComplete);
app.use(Row);
app.use(Col);
app.use(InputNumber);
app.use(Modal);
app.use(Descriptions);
app.use(Switch);
app.use(Divider);
app.use(Upload);
app.use(Typography);
app.use(Message);
export default {
    moment
}

// 设置全局属性
app.config.globalProperties.$mainServerUrl = 'https://formulatorapi.imlpc.com';
// app.config.globalProperties.$mainServerUrl = 'http://127.0.0.1:8000';
// app.config.globalProperties.$alternateServerUrl = 'https://zdata.eicp.vip';
app.config.globalProperties.$alternateServerUrl = 'http://127.0.0.1:8000';

app.mount("#app");