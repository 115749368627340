import { createRouter, createWebHashHistory } from "vue-router";
import NotFound from "@/views/Error/404.vue"
import Forbidden from "@/views/Error/403.vue"
import NProgress from "nprogress"
// import findLast from "lodash/findLast"
// import { notification } from "ant-design-vue";
// import { check, isLogin } from "../utils/auth";

const routes = [
  {
    path: "/",
    name: "Index",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "index" */ "@/layouts/index/IndexBasic.vue"),
  },
  {
    path: "/import_excel",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ImportExcel" */ "@/layouts/import_excel/ImportExcelBasicLayout.vue"),
    children: [
      {
        path: "/import_excel",
        redirect: "/import_excel/welcome",
        props: true,
      },
      {
        hideInMenu: false,
        path: "/import_excel/welcome",
        name: "ImportExcelWelcome",
        siderName: "ImportExcel",
        title: "欢迎",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "ImportExcel" */ "@/views/import_excel/ImportExcelWelcome.vue"),
      },
      {
        hideInMenu: true,
        props: true,
        path: "/import_excel/import/:routeSelectedImportMethod",
        name: "ImportExcelImportp",
        siderName: "ImportExcel",
        title: "数据导入",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "ImportExcel" */ "@/views/import_excel/ImportExcelImport.vue"),
      },
      {
        hideInMenu: false,
        path: "/import_excel/import",
        name: "ImportExcelImport",
        siderName: "ImportExcel",
        title: "数据导入",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "ImportExcel" */ "@/views/import_excel/ImportExcelImport.vue"),
      },
      {
        hideInMenu: false,
        path: "/import_excel/show_records",
        name: "ImportExcelShowRecords",
        siderName: "ImportExcel",
        title: "记录查询",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "ImportExcel" */ "@/views/import_excel/ImportExcelShowRecords.vue"),
      },
      {
        hideInMenu: false,
        path: "/import_excel/delete_records",
        name: "ImportExcelDeleteRecords",
        siderName: "ImportExcel",
        title: "记录清理",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "ImportExcel" */ "@/views/import_excel/ImportExcelDeleteRecords.vue"),
      },
    ]
  },
  {
    path: "/mainapp",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Mainapp" */ "@/layouts/mainapp/MainappBasicLayout.vue"),
    children: [
      {
        path: "/mainapp",
        redirect: "/mainapp/welcome",
        props: true,
      },
      {
        hideInMenu: false,
        path: "/mainapp/welcome",
        name: "MainappWelcome",
        siderName: "Mainapp",
        title: "欢迎",
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Mainapp" */ "@/views/mainapp/MainappWelcome.vue"),
      },
      {
        hideInMenu: true,
        path: '/mainapp/mainapp_exec_cmd/:routeSelectedCmd',
        name: "MainappExecCMD",
        siderName: "Mainapp",
        props: true,
        title: "执行数据处理",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Mainapp" */ "@/views/mainapp/MainappExecCMD.vue"),
      },
      {
        hideInMenu: false,
        path: '/mainapp/mainapp_exec_cmd',
        name: "MainappExecCMDS",
        siderName: "Mainapp",
        title: "执行数据处理",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Mainapp" */ "@/views/mainapp/MainappExecCMD.vue"),
      },
      {
        hideInMenu: false,
        path: '/mainapp/ingredients_status',
        name: "MainappIngredientsStatus",
        siderName: "Mainapp",
        title: "原料状态",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Mainapp" */ "@/views/mainapp/IngredientsStatus.vue"),
      },
    ]
  },
  {
    path: "/basic_data",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "BasicData" */ "@/layouts/basic_data/BasicDataBasicLayout.vue"),
    children: [
      {
        path: "/basic_data",
        redirect: "/basic_data/welcome"
      },
      {
        hideInMenu: false,
        path: "/basic_data/welcome",
        name: "BasicDataWelcome",
        siderName: "BasicData",
        title: "欢迎",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "BasicData" */ "@/views/basic_data/BasicDataWelcome.vue"),
      },
      {
        hideInMenu: false,
        path: "/basic_data/basic_data_manager",
        name: "BasicDataManager",
        siderName: "BasicData",
        title: "元数据管理",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "BasicData" */ "@/views/basic_data/BasicDataManager.vue"),
      },
    ]
  },
  {
    path: "/nutrition_indicators",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "NutritionIndicators" */ "@/layouts/nutrition_indicators/NutritionIndicatorsBasicLayout.vue"),
    children: [
      {
        path: "/nutrition_indicators",
        redirect: "/nutrition_indicators/welcome"
      },
      {
        hideInMenu: false,
        path: "/nutrition_indicators/welcome",
        name: "NutritionIndicatorsWelcome",
        siderName: "NutritionIndicators",
        title: "欢迎",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "NutritionIndicators" */ "@/views/nutrition_indicators/NutritionIndicatorsWelcome.vue"),
      },
      {
        hideInMenu: false,
        path: "/nutrition_indicators/nutrition_indicators_exec_cmd",
        name: "NutritionIndicatorsExecCMD",
        siderName: "NutritionIndicators",
        title: "营养指标数据处理",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "NutritionIndicators" */ "@/views/nutrition_indicators/NutritionIndicatorsExecCMD.vue"),
      },
    ]
  },
  {
    path: "/403",
    name: "403",
    hideInMenu: true,
    component: Forbidden
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  props:true,
});

// to：将要到达的路由，from现在的路由，调用next进行下一步。next
router.beforeEach((to, from) => {
  if (to.path !== from.path) {
    NProgress.start();
  }
  // // 即将访问的路由to.matched中取得数组，找到最近的authority元信息，如果找到了就可以进行处理。
  // // authority 参数是在路由中定义的元信息，可以参考路由中的元信息。
  // const record = findLast(to.matched, record => record.meta.authority);
  // if (record && !check(record.meta.authority)) {
  //   // 此时没有权限，再判断是否登陆
  //   // 查看路由是否已经跳转，如果已经跳转则不触发next
  //   if (!isLogin() && to.path !== "/user/login") {
  //     next({
  //       path: "/user/login"
  //     });
  //   } else if (to.path !== "/403") {
  //     // 如果已经登陆
  //     notification.error({
  //       message: "403",
  //       description: "您没有权限访问该页面，如有需要请联系管理员。"
  //     });
  //     next({
  //       path: "/403"
  //     });
  //   }
  //   // 由于上述已经next（），不会进入下一步的afterEach，所以需要手动禁用
  //   NProgress.done();
  // }
  // next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
